<template>
  <div
    id="layout"
    class="isolate grid min-h-dvh grid-rows-[auto,minmax(0,1fr),auto]"
  >
    <PageHeader
      class="shadow-dark/25 sticky top-0 shadow print:!static print:!bg-white print:dark:!bg-white"
    />

    <main id="main" class="relative">
      <MessageController />
      <div class="relative flex min-h-full flex-col">
        <slot />
      </div>
    </main>

    <PageFooter class="print:hidden" />
  </div>
</template>

<script setup lang="ts">
const { design: currentDesign } = useCms()
const { design, applyDesign } = useDesign()

watch(
  currentDesign,
  () => {
    design.value = currentDesign.value
    applyDesign()
  },
  { immediate: true }
)

defineOptions({
  name: 'DefaultLayout',
})
</script>
